<template>
  <div>
    <div class="floating-buttons">
      <router-link class="fl-button" :to="{ path: '/cardgroup/edit/' }"
        ><i class="customicon customicon-plus"></i
      ></router-link>
    </div>
    <h1 class="py-6">Kártyacsoport lista</h1>

    <div class="row">
      <div class="col-xs-12">
        <h2>Kereső</h2>
      </div>
      <div class="col-xs-2">
        <inputbox
          :inputMaskOptions="{
            regex: '[a-zA-Z. öüóőúéáűíÖÜÓŐÚÉÁŰÍ0-9-]+',
            jitMasking: true,
          }"
          @keydownEnter="searchClick"
          label="Csoport megnevezés"
          id="name"
          name="name"
          v-model="search.name"
        ></inputbox>
      </div>
      <div class="col-xs-3">
        <button
          class="button with-icon"
          style="margin-top: 24px"
          type="button"
          v-on:click="searchClick"
        >
          <i class="customicon customicon-search"></i>Keresés
        </button>
      </div>
    </div>

    <div v-if="this.groups.length > 0" class="table-with-title">
      <h2>Kártyacsoport lista</h2>
      <table class="list">
        <thead>
          <tr>
            <th>ID</th>
            <th>Megnevezés</th>
            <th>Kártya db</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groups">
            <td>{{ group.element_id }}</td>
            <td>{{ group.name }}</td>
            <td>{{ group.card_amount }}</td>
            <td class="buttons">
              <router-link
                class="small-button"
                :to="{
                  path: '/cardgroup/edit/',
                  query: { groupID: group.element_id },
                }"
                ><i class="customicon customicon-pencil"></i
              ></router-link>
              <a
                class="small-button"
                href="javascript:void(0)"
                v-on:click="del(group.element_id)"
                ><i class="customicon customicon-trash"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      v-if="groups.length > 0"
      showcombo="1"
      :limit="query.limit"
      :amount="query.amount"
      v-on:pageChanged="pageChanged"
      v-model="query.page"
    ></pagination>
  </div>
</template>
<script>
export default {
  components: {},

  data: function () {
    return {
      query: {
        name: "",
        page: 1,
        limit: 100,
        amount: 0,
      },

      search: {
        name: "",
      },

      groups: [],
    };
  },

  watch: {
    $route: "routerChange",
  },

  created: function () {
    this.routerChange();
  },

  mounted: function () {},

  methods: {
    routerChange: function () {
      if (Object.keys(this.$route.query).length != 0) {
        // Lementi az url paraméterben átadott keresési értékeket
        this.query = JSON.parse(JSON.stringify(this.$route.query));

        // Űrlap mezőket beállítja az url paraméterben átadott értékekre
        this.search.name = this.query.name;
      } else {
        this.query = {
          name: "",
          limit: 100,
          page: 1,
        };

        this.search.name = "";
      }

      this.getGroups();
    },

    searchClick: function () {
      this.query.page = 1;
      this.query.name = this.search.name;

      this.$router.push({ path: "/cardgroups", query: this.query });
    },

    pageChanged: function (val) {
      this.query.page = val;
      this.$router.push({ path: "/cardgroups", query: this.query });
    },

    getGroups: function () {
      var _this = this;

      this.$root.checkRootUserHasSet(function () {
        _this.$root.ajax("card_group/list/" + _this.$store.state.token, {
          data: _this.query,
          method: "POST",
          success: function (response) {
            _this.groups = response.groups;
            _this.query.amount = response.amount;
          },
        });
      });
    },

    del: function (id) {
      var _this = this;

      swal({
        title: "Törli a csoportot?",
        text: "",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Igen",
            value: true,
            closeModal: true,
          },
          cancel: {
            text: "Nem",
            value: false,
            visible: true,
            closeModal: true,
          },
        },
      }).then(function (result) {
        if (result) {
          _this.$root.ajax(
            "card_group/delete/" + _this.$store.state.token + "/" + id,
            {
              success: function (response) {
                _this.getGroups();
              },
            }
          );
        }
      });
    },
  },
};
</script>
