var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"floating-buttons"},[_c('router-link',{staticClass:"fl-button",attrs:{"to":{ path: '/cardgroup/edit/' }}},[_c('i',{staticClass:"customicon customicon-plus"})])],1),_c('h1',{staticClass:"py-6"},[_vm._v("Kártyacsoport lista")]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xs-2"},[_c('inputbox',{attrs:{"inputMaskOptions":{
          regex: '[a-zA-Z. öüóőúéáűíÖÜÓŐÚÉÁŰÍ0-9-]+',
          jitMasking: true,
        },"label":"Csoport megnevezés","id":"name","name":"name"},on:{"keydownEnter":_vm.searchClick},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('div',{staticClass:"col-xs-3"},[_c('button',{staticClass:"button with-icon",staticStyle:{"margin-top":"24px"},attrs:{"type":"button"},on:{"click":_vm.searchClick}},[_c('i',{staticClass:"customicon customicon-search"}),_vm._v("Keresés ")])])]),(this.groups.length > 0)?_c('div',{staticClass:"table-with-title"},[_c('h2',[_vm._v("Kártyacsoport lista")]),_c('table',{staticClass:"list"},[_vm._m(1),_c('tbody',_vm._l((_vm.groups),function(group){return _c('tr',[_c('td',[_vm._v(_vm._s(group.element_id))]),_c('td',[_vm._v(_vm._s(group.name))]),_c('td',[_vm._v(_vm._s(group.card_amount))]),_c('td',{staticClass:"buttons"},[_c('router-link',{staticClass:"small-button",attrs:{"to":{
                path: '/cardgroup/edit/',
                query: { groupID: group.element_id },
              }}},[_c('i',{staticClass:"customicon customicon-pencil"})]),_c('a',{staticClass:"small-button",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.del(group.element_id)}}},[_c('i',{staticClass:"customicon customicon-trash"})])],1)])}),0)])]):_vm._e(),(_vm.groups.length > 0)?_c('pagination',{attrs:{"showcombo":"1","limit":_vm.query.limit,"amount":_vm.query.amount},on:{"pageChanged":_vm.pageChanged},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12"},[_c('h2',[_vm._v("Kereső")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Megnevezés")]),_c('th',[_vm._v("Kártya db")]),_c('th')])])
}]

export { render, staticRenderFns }